import React from "react";
import Flyer7 from "./../../../../img/Flyer7.webp";

function DetailsEvenements() {
  return (
    <section className="relative">
      <div className="petal absolute inset-0">
        <div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/077526e80df6595ab71c00788b3a9e12ea1dfdd7ed1017c00c1c9e91f217aab0?apiKey=aa921c22d5e8470a9701bc1a79b29d5d&"
            alt=""
            className="absolute top-10 left-14 w-9 aspect-[0.67] fill-yellow-600 fill-opacity-20"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/82d0cc958661239053bacf859eb20cb7705bd8eaaa3a7ec83096b7f29ce6c224?apiKey=aa921c22d5e8470a9701bc1a79b29d5d&"
            alt=""
            className="absolute top-1/4 right-10 w-9 aspect-[0.56] fill-teal-300 fill-opacity-20"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/077526e80df6595ab71c00788b3a9e12ea1dfdd7ed1017c00c1c9e91f217aab0?apiKey=aa921c22d5e8470a9701bc1a79b29d5d&"
            alt=""
            className="absolute top-1/3 left-1/4 rotate-45 w-9 aspect-[0.67] fill-yellow-600 fill-opacity-20"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/82d0cc958661239053bacf859eb20cb7705bd8eaaa3a7ec83096b7f29ce6c224?apiKey=aa921c22d5e8470a9701bc1a79b29d5d&"
            alt=""
            className="absolute top-1/2 left-10 w-9 aspect-[0.56] fill-teal-300 fill-opacity-20"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/077526e80df6595ab71c00788b3a9e12ea1dfdd7ed1017c00c1c9e91f217aab0?apiKey=aa921c22d5e8470a9701bc1a79b29d5d&"
            alt=""
            className="absolute top-2/3 right-16 w-9 aspect-[0.67] fill-yellow-600 fill-opacity-20"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/077526e80df6595ab71c00788b3a9e12ea1dfdd7ed1017c00c1c9e91f217aab0?apiKey=aa921c22d5e8470a9701bc1a79b29d5d&"
            alt=""
            className="absolute top-3/4 right-1/4 w-9 aspect-[0.67] fill-yellow-600 fill-opacity-20"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/077526e80df6595ab71c00788b3a9e12ea1dfdd7ed1017c00c1c9e91f217aab0?apiKey=aa921c22d5e8470a9701bc1a79b29d5d&"
            alt=""
            className="absolute bottom-20 left-14 w-9 aspect-[0.67] fill-yellow-600 fill-opacity-20"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/82d0cc958661239053bacf859eb20cb7705bd8eaaa3a7ec83096b7f29ce6c224?apiKey=aa921c22d5e8470a9701bc1a79b29d5d&"
            alt=""
            className="absolute bottom-10 right-1/4 w-9 aspect-[0.56] fill-teal-300 fill-opacity-20"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/077526e80df6595ab71c00788b3a9e12ea1dfdd7ed1017c00c1c9e91f217aab0?apiKey=aa921c22d5e8470a9701bc1a79b29d5d&"
            alt=""
            className="absolute top-1/4 right-2/3 rotate-45 w-9 aspect-[0.67] fill-yellow-600 fill-opacity-20"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/82d0cc958661239053bacf859eb20cb7705bd8eaaa3a7ec83096b7f29ce6c224?apiKey=aa921c22d5e8470a9701bc1a79b29d5d&"
            alt=""
            className="absolute bottom-1/3 left-1/3 w-9 aspect-[0.56] fill-teal-300 fill-opacity-20"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/077526e80df6595ab71c00788b3a9e12ea1dfdd7ed1017c00c1c9e91f217aab0?apiKey=aa921c22d5e8470a9701bc1a79b29d5d&"
            alt=""
            className="absolute bottom-1/4 right-1/2 rotate-45 w-9 aspect-[0.67] fill-yellow-600 fill-opacity-20"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/077526e80df6595ab71c00788b3a9e12ea1dfdd7ed1017c00c1c9e91f217aab0?apiKey=aa921c22d5e8470a9701bc1a79b29d5d&"
            alt=""
            className="absolute bottom-1/6 left-1/6 w-9 aspect-[0.67] fill-yellow-600 fill-opacity-20"
          />
        </div>
      </div>

      <div className="relative flex flex-col w-full mx-auto text-gray-900 py-10 px-5 md:w-[60%]">
        {/* Titre */}
        <h1 className="text-4xl font-bold mb-6">Qui est Marc Loopuyt ?</h1>

        {/* Premier paragraphe*/}
        <p className="text-justify leading-7 mb-6">
        Marc Loopuyt a fait de sa vie une quête musicale en résidant longuement auprès de ses maîtres: Andalousie, Maroc, Turquie et Azerbaydjan. Il reçoit aussi d'eux tout un corpus de commentaires sapientiels sur les arcanes de la Musique. A Fès, sa fréquentation du grand islamologue Titus Burckhardt va l'inciter à systématiser ce recueil dans la perspective de l'unité transcendante des traditions esthétiques. Outre ses divers CD, il publie:" Un Luth mythique de Damas" ( Cité de la Musique) et:" le Voyage, la Musique et le Fil d'Ariane "( les Trois Colonnes, à paraître). Il propose ici une causerie fondée sur ces rencontres remarquables et sur des anecdotes précises et vivantes qui illustrent les principes et les méthodes d' intériorisation de l'art traditionnel. Cet exposé qui concerne la musique mais aussi l'art en généra, inclura de petits intermèdes joués sur le luth oriental.
        </p>
        
        {/* Image */}
        <img loading="lazy" src={Flyer7} alt="Flyer" className="object-contain rounded mb-6 w-full " />

        {/* Deuxième paragraphe 
        <p className="text-justify leading-7 mb-6">
          Une autre réponse courante à la question a alors été envisagée pour compléter la première : “Une vie a du sens lorsque nous oeuvrons à quelque chose qui a une valeur indépendante de nous-même, c’est-à-dire objective. Cette dimension garantit alors que l'activité puisse être regardée comme estimable, comme douée en elle-même de valeur, ce qui semble essentiel à une vie douée de sens. C’est donc la réunion de ces deux réponses qui nous est apparue constituer une réponse satisfaisante à notre question : une vie a du sens lorsqu’une personne aime ce qu’elle fait et que ce qu’elle aime est digne de l’être.
        </p>*/}

        {/* Bouton 
        <button  className="px-6 py-3 text-center bg-cyan-600 text-white font-bold rounded hover:bg-cyan-700 transition duration-150">
          Je m'inscris
        </button>*/}
      </div>
    </section>
  );
}

export default DetailsEvenements;
